import React, { useState } from "react";
import styles from "./dropdownMonths.module.scss";
import { useParams } from "react-router-dom";

const DropdownMonths = ({ months, selectedMonth, handleMonthClick }) => {
    const [isOpen, setIsOpen] = useState(false);
    const { lang } = useParams();

    return (
        <div className={styles.dropdownWrapper}>
            <button
                className={styles.dropdownToggle}
                onClick={() => setIsOpen(!isOpen)}
            >
                {selectedMonth
                    ? new Date(selectedMonth + "-01").toLocaleDateString(lang === "en" ? "en-US" : "pl-PL", { year: "numeric", month: "long" })
                    : lang === "en" ? "Select month" : "Wybierz miesiąc"}
                <span className={`${styles.arrow} ${isOpen ? styles.open : ""}`} />
            </button>

            {isOpen && (
                <div className={styles.dropdownMenu}>
                    <button
                        onClick={() => {
                            sessionStorage.removeItem("selectedMonth");
                            handleMonthClick(null);
                            setIsOpen(false);
                        }}
                        className={!selectedMonth ? styles.activeMonth : ""}
                    >
                        {lang === "en" ? "All" : "Wszystkie"}
                    </button>
                    {months.map((month, index) => (
                        <button
                            key={index}
                            onClick={() => { handleMonthClick(month); setIsOpen(false); }}
                            className={selectedMonth === month ? styles.activeMonth : ""}
                        >
                            {new Date(month + "-01").toLocaleDateString(lang === "en" ? "en-US" : "pl-PL", { year: "numeric", month: "long" })}
                        </button>
                    ))}
                </div>
            )}
        </div>
    );
};

export default DropdownMonths;
