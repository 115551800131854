import {
    months,
    monthsEn,
    monthsShort,
    monthsShortEn,
} from "../../assets/constanst/constans";

export const exportYear = (date) => {
    date = new Date(date);

    return date.getFullYear();
};

export const exportMonth = (date, lang) => {
    date = new Date(date);
    if (lang == "en") return monthsEn[date.getMonth()];
    else return months[date.getMonth()];
};

export const exportMonthShort = (date, lang) => {
    date = new Date(date);
    if (lang == "en") return monthsShortEn[date.getMonth()];
    else return monthsShort[date.getMonth()];
};

export const exportDay = (date) => {
    date = new Date(date);
    date = date.getDate();
    if (date.toString().length === 1) {
        date = "0" + date;
    }
    return date;
};

export const orphans = () => {
    document.querySelectorAll("span, h2, h3, h1, p").forEach((span) => {
        let tekst = span.innerHTML;
        tekst = tekst.replace(/(\s)([^<,]{1,2})[\s]+/g, "$1$2&nbsp;");
        span.innerHTML = tekst;
    });
};
