import React, { forwardRef, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import { Link } from "react-router-dom";
import { Loader } from "../../Components/Loader/loader";
import SearchComponent from "../../Components/SearchComponent/searchComponent";
import Footer from "../Footer/footer";
import ListItem from "../../Components/ListItem/listItem";

import authorMockup from "../../assets/images/mockup/autor.jpg";

import styles from "./mainPage.module.scss";
import {
    getArticle,
    getArticle2,
    getCategories,
} from "../../assets/api/apiCall";
import scrollTo from "../../Components/Helpers/scrollTo";
import MainSplide from "../../Components/Splide/main/mainSplide";
import translation from "../../Components/Helpers/translation";
import useWindowSize from "../../Components/Helpers/hooks/useWindowSize";
import { Helmet } from "react-helmet";
import DropdownMonths from "../../Components/DropdownMonth/DropdownMonth";

function MainPage({ categories, openSortRef }) {
    const [dataSlider, setDataSlider] = useState(null);
    const [data, setData] = useState(null);
    const [dataItems, setDataItems] = useState(null);
    // const [categories, setCategories] = useState(null);

    const [countBefore, setCountBefore] = useState(null);
    const [isSearch, setIsSearch] = useState(false);

    const [closed, setClosed] = useState(true);

    const loaderRef = useRef(null);
    const loaderRefBig = useRef(null);
    const shadowRef = useRef(null);
    const midleRef = useRef(null);
    // const openSortRef = useRef(null)
    const topRef = useRef(null);
    const { lang } = useParams();
    const size = useWindowSize();
    const [isLoading, setIsLoading] = useState(false);
    const [selectedMonth, setSelectedMonth] = useState(null);
    const months = dataItems ? getMonthsList(dataItems) : [];
    const groupedArticles = dataItems ? groupByMonth(dataItems) : {};
    const filteredArticles = selectedMonth ? groupedArticles[selectedMonth] : dataItems;

    const handleLoadMore = () => {
        shadowRef.current.classList.add(styles.itemsWrapperShadowShow);
        loaderRef.current.classList.add(styles.loaderShow);

        const queryString = window.location.origin + "/" + lang;
        const urlParams = new URLSearchParams(window.location.search);
        let readPage = urlParams.get("page");
        let readCategories = urlParams.get("categories");
        let readSearch = urlParams.get("search");
        let isFirst = false;

        let querySearch = `?lang=${lang}`;
        let search = ``;
        

        if (readSearch) {
            search += `?search=${readSearch}`;
            querySearch += `&search=${readSearch}`;
            isFirst = true;
        }
        if (readCategories) {
            search += `${isFirst ? "?" : "&"}categories=${readCategories}`;
            querySearch += `&categories=${readCategories}`;
        }
        if (readPage) {
            search += `${isFirst ? "?" : "&"}page=${parseInt(readPage) + 1}`;
            querySearch += `&page=${parseInt(readPage) + 1}`;
        } else {
            search += `${isFirst ? "?" : "&"}page=${2}`;
            querySearch += `&page=${2}`;
        }

        window.history.pushState({}, null, queryString + search);

        setTimeout(() => {
            setCountBefore(dataItems.length);
            getArticle2(querySearch).then((json) => {
                let combined = dataItems.concat(json.items);
                // console.log("array ", combined);
                setDataItems(combined);
                // json.items.forEach(element => {
                //     setDataItems([...dataItems, element])
                // });

                setTimeout(() => {
                    shadowRef.current.classList.remove(
                        styles.itemsWrapperShadowShow
                    );
                    loaderRef.current.classList.remove(styles.loaderShow);

                    const dates = document.querySelectorAll(
                        '[id^="searchElement"]'
                    );
                    const newFirstElement = document.getElementById(
                        `searchElement-${dates.length - 1}`
                    );
                    scrollTo(
                        newFirstElement.offsetTop +
                        newFirstElement.offsetHeight,
                        300
                    );
                }, 400);
            });
        }, 100);
    };

    function categoryButtonClick(index, value, close = false) {
        if (close) setClosed(!closed);
        shadowRef.current?.classList.add(styles.itemsWrapperShadowShow);
        loaderRefBig.current?.classList.add(styles.loaderShow);

        if (index === "all") {
            setTimeout(() => {
                setIsSearch(false);
            }, 300);
            if (openSortRef)
                if (openSortRef.current)
                    // openSortRef.current.textContent = size.width > 800 ? translation.site.main.categories.showList : translation.site.main.categories.showListShort
                    openSortRef.current.textContent =
                        translation.site.main.categories.showList;
        } else {
            setTimeout(() => {
                setIsSearch(true);
            }, 300);
            if (openSortRef)
                if (openSortRef.current)
                    openSortRef.current.textContent = value;
        }

        const urlParams = new URLSearchParams(window.location.search);
        const queryString = window.location.origin + "/" + lang;
        let readSearch = urlParams.get("search");
        let querySearch = `?lang=${lang}`;
        let search = ``;

        if (readSearch) {
            search += `?search=${readSearch}&categories=${index}&page=1`;
            querySearch += `&search=${readSearch}&categories=${index}&page=1`;
        } else {
            search += `?categories=${index}&page=1`;
            querySearch += `&categories=${index}&page=1`;
        }

        window.history.pushState({}, null, queryString + search);

        //or reload
        // window.location.search = search;

        getArticle2(querySearch).then((json) => {
            if (json.items.length == 0) {
                for (let i = 0; i < dataItems.length; i++) {
                    const obj = document.getElementById(`searchElement-${i}`);
                    obj.style.height = obj.scrollHeight + "px";
                    setTimeout(() => {
                        obj.style.height = 0;
                    }, 100);
                }
            }
            // const obj = document.getElementById(`searchElement-${dataItems.length - 1}`)
            if (dataItems.length > json.items.length) {
                const index = dataItems.length - json.items.length;

                for (
                    let i = dataItems.length - index;
                    i < dataItems.length;
                    i++
                ) {
                    const obj2 = document.getElementById(`searchElement-${i}`);
                    obj2.style.height = obj2.scrollHeight + "px";
                    setTimeout(() => {
                        obj2.style.height = 0;
                    }, 100);
                }
            }

            setCountBefore(dataItems.length);
            setTimeout(() => {
                setData(json);
                setDataItems(json.items);
                shadowRef.current.classList.remove(
                    styles.itemsWrapperShadowShow
                );
                loaderRef.current.classList.remove(styles.loaderShow);
            }, 400);

            // setTimeout(() => {
            //     setData(json)
            //     setDataItems(json.items)
            // }, 400)
            // setTimeout(() => {
            //     shadowRef.current.classList.remove(styles.itemsWrapperShadowShow)
            //     loaderRef.current.classList.remove(styles.loaderShow)
            // }, 500);
        });
    }

    function tagClick(readName, readId) {
        const el = document.getElementById("openSort");
        if (el) {
            el.innerText = readName;
            const topEl = document.getElementById("mainListTop");
            scrollTo(topEl.clientHeight, 500);

            const queryString = window.location.origin + "/" + lang;
            let search = `?categories=${readId}&page=1`;
            window.history.pushState({}, null, queryString + search);

            categoryButtonClick(readId, readName);
        }
    }



    function groupByMonth(items) {
        return items.reduce((acc, item) => {
            const month = item.created_at.substring(0, 7);
            if (!acc[month]) acc[month] = [];
            acc[month].push(item);
            return acc;
        }, {});
    }
    function getMonthsList(items) {
        const monthsSet = new Set(items.map(item => item.created_at.substring(0, 7)));
        return Array.from(monthsSet).sort((a, b) => new Date(b) - new Date(a));
    }
 
    function handleMonthClick(month) {
        sessionStorage.setItem("selectedMonth", month || "all");
        setIsLoading(true);
    
        setTimeout(() => {
            setSelectedMonth(month);
            setIsLoading(false);
        }, 500);
    }
    



    useEffect(() => {
        if (!data) {
            setTimeout(() => {
                const urlParams = new URLSearchParams(window.location.search);
                let readPage = urlParams.get("page");
                let search = urlParams.get("search");
                let readCategories = urlParams.get("categories");
                let limit = urlParams.get("limit");

                let querySearch = `?lang=${lang}`;
                if (search) {
                    querySearch += `&search=${search}`;
                    setIsSearch(true);
                }
                if (readPage) querySearch += `&page=${parseInt(readPage)}`;
                if (readCategories)
                    querySearch += `&categories=${readCategories}`;
                if (limit) {
                    limit = parseInt(limit);
                    if (limit)
                        if (Number.isInteger(limit)) {
                            querySearch += `&limit=${limit}`;
                        }
                }
                querySearch += "&pagination=true";
                getArticle2(querySearch).then((json) => {
                    setData(json);

                    setDataItems(json.items);
                    setCountBefore(json.items.length);
                });
            }, 100);
        }
        if (!dataSlider) {
            getArticle2(`?lang=${lang}&slider=true`).then((json) => {
                setDataSlider(json);
            });
        }
    }, [lang, data, dataItems, dataSlider, isSearch]);
    

    
    useEffect(() => {
        const savedData = sessionStorage.getItem("articlesData");
    
        if (savedData) {
            const parsedData = JSON.parse(savedData);
            setData(parsedData);
            setDataItems(parsedData.items);
            setCountBefore(parsedData.items.length);
        } else {
            getArticle2(`?lang=${lang}&pagination=true`).then((json) => {
                setData(json);
                setDataItems(json.items);
                setCountBefore(json.items.length);
    
                sessionStorage.setItem("articlesData", JSON.stringify(json));
            });
        }
    }, [lang]);

    
    useEffect(() => {
        const savedScrollPosition = sessionStorage.getItem("scrollPosition");
        const fromArticle = sessionStorage.getItem("fromArticle");
    
        if (savedScrollPosition && fromArticle === "true") {
            setTimeout(() => {
                window.scrollTo(0, parseInt(savedScrollPosition, 10));
            }, 50);
        }
    
        sessionStorage.removeItem("fromArticle");
    }, [dataItems]);
    
    useEffect(() => {
        const savedMonth = sessionStorage.getItem("selectedMonth");
        
        if (savedMonth && savedMonth !== "all") {
            setSelectedMonth(savedMonth);
        }
    }, []);
    
    
    
    return (
        <>
            <Helmet>
                <title>{`Nifc Blog - ${lang == "en" ? "Main Page" : "Strona główna"
                    }`}</title>
                <meta
                    name="description"
                    content={
                        lang == "en"
                            ? "The Nifc blog is the largest collection of all content related to the Romantic era"
                            : "Blog Nifc to największy zbiór wszystkich treści połączonych z epoką romantyzmu"
                    }
                />
            </Helmet>
            <Loader data={data || dataSlider} scroll={false} />
            <div className={styles.mainPageWrapper}>
                <div id="mainListTop" className={styles.top}>
                    <MainSplide data={dataSlider} tagClick={tagClick} />
                </div>
                <div className={styles.middle} ref={midleRef}>
                    <SearchComponent
                        openSortRef={openSortRef}
                        setClosed={setClosed}
                        closed={closed}
                        categoryButtonClick={categoryButtonClick}
                        setData={setData}
                        dataItems={dataItems}
                        setDataItems={setDataItems}
                        setCountBefore={setCountBefore}
                        countBefore={countBefore}
                        categories={categories}
                        setIsSearch={setIsSearch}
                        shadowRef={shadowRef}
                        loaderRef={loaderRefBig}
                        midleRef={midleRef}
                    />

                    <div className={styles.dropdownMonths}>
                        <DropdownMonths
                            months={months}
                            selectedMonth={selectedMonth}
                            handleMonthClick={handleMonthClick}
                        />
                    </div>

                    <div
                        className={`${styles.itemsWrapper} ${styles.searched}`}
                    >
                        <div
                            className={`${styles.search} ${isSearch ? styles.active : ""
                                }`}
                        >
                            <span className={`${styles.searchText} `}>
                                {translation.site.main.searchResults}
                            </span>
                            <span className={styles.counter}>
                                {data
                                    ? data.metaData
                                        ? data.metaData.totalItems
                                        : "0"
                                    : "0"}
                            </span>
                        </div>
                        <div className={styles.articlesWrapper}>
                            {isLoading ? (
                                <div className={styles.loaderContainer}>
                                    <Loader />
                                </div>
                            ) : filteredArticles && filteredArticles.length > 0 ? (
                                filteredArticles.map((item, index) => (
                                    <ListItem
                                        id={`searchElement-${index}`}
                                        item={item}
                                        key={index}
                                        animation={index >= countBefore}
                                        categoryButtonClick={categoryButtonClick}
                                        tagClick={tagClick}
                                    />
                                ))
                            ) : (
                                <p>Brak artykułów w tym miesiącu</p>
                            )}
                        </div>

                        <div
                            className={styles.itemsWrapperShadow}
                            ref={shadowRef}
                        >
                            <div
                                className={`${styles.loader} ${styles.loaderBig}`}
                                ref={loaderRefBig}
                            />
                        </div>
                    </div>
                    <div className={styles.loader} ref={loaderRef} />
                    {data ? (
                        data.metaData ? (
                            dataItems ? (
                                data.metaData.perPage *
                                    data.metaData.currentPage <
                                    data.metaData.totalItems &&
                                    dataItems.length < data.metaData.totalItems ? (
                                    <button
                                        type={"button"}
                                        className={styles.loadMore}
                                        onClick={handleLoadMore}
                                    >
                                        {translation.site.btns.loadMore}
                                    </button>
                                ) : null
                            ) : null
                        ) : null
                    ) : null}
                </div>
                <Footer />
            </div>
        </>
    );
}

export default MainPage;
