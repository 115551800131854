import React, { useEffect, useRef, useState } from "react";

import styles from './searchComponent.module.scss'
import translation from "../Helpers/translation";
import SlideUpDown from "../Helpers/slideUpDown/slideUpDown";
import useWindowSize from "../Helpers/hooks/useWindowSize";
import { getArticle2 } from "../../assets/api/apiCall";
import { useParams } from "react-router-dom";
import scrollTo from "../Helpers/scrollTo";

function SearchComponent({ setData, dataItems, setDataItems, setCountBefore, setIsSearch, shadowRef, loaderRef, midleRef, countBefore, categoryButtonClick, setClosed, closed, categories, openSortRef,  }) {
    const searchInputRef = useRef()
    const inputContainer = useRef()
    const [hideCategory, setHideCategory] = useState(false)
    const size = useWindowSize()
    let { lang } = useParams();

    function createSearchElement(value) {
        const newText = document.createElement("div")
        newText.classList.add(styles.searchText)
        newText.innerText = value

        const newCross = document.createElement("div")
        newCross.classList.add(styles.searchBtn)
        newCross.addEventListener('click', () => {
            const urlParams = new URLSearchParams(window.location.search)
            let readSearch = urlParams.get('search')
            let readCategories = urlParams.get('categories')

            readSearch = readSearch.split(',')

            const index = readSearch.indexOf(newText.innerText);
            if (index > -1) {
                readSearch.splice(index, 1);
            }

            const queryString = window.location.origin + '/' + lang;
            let search = ''
            if (readSearch) {
                search = `?search=${readSearch}`;
                if (readCategories)
                    search += `&categories=${readCategories}`
            } else {
                if (readCategories)
                    search += `?categories=${readCategories}&page=1`
                else
                    search = '?page=1'
            }

            window.history.pushState({}, null, queryString + search);
            newCross.parentElement.remove();
            searchInfo(null, true)

            sessionStorage.setItem("searchContainer", inputContainer.current.innerHTML);
        })

        const newEl = document.createElement("div")
        newEl.classList.add(styles.searchElement)
        newEl.appendChild(newText)
        newEl.appendChild(newCross)

        inputContainer.current.appendChild(newEl)
    }

    function searchInfo(event, reload = false) {
        if (event)
            event.preventDefault();
        setHideCategory(false)
        const value = searchInputRef.current.value.trim()
        if (value || reload == true) {
            if (value)
                searchInputRef.current.readOnly = true;

            shadowRef.current.classList.add(styles.itemsWrapperShadowShow)
            loaderRef.current.classList.add(styles.loaderShow)
            scrollTo(midleRef.current.offsetTop, 1000)

            if (!reload) {
                createSearchElement(value)
            }

            sessionStorage.setItem("searchContainer", inputContainer.current.innerHTML);
            sessionStorage.setItem("searchQuery", value);

            const urlParams = new URLSearchParams(window.location.search)
            let readSearch = urlParams.get('search')
            let readCategories = urlParams.get('categories')

            const queryString = window.location.origin + '/' + lang;
            let querySearch = null;
            let search = null;

            if (readSearch) {
                setTimeout(() => {
                    setIsSearch(true)
                }, 300)

                if (reload) {
                    querySearch = `?lang=${lang}&search=${readSearch}`
                    search = `?search=${readSearch}`
                    if (readCategories) {
                        querySearch += `&categories=${readCategories}`
                        search += `&categories=${readCategories}&page=1`
                    } else {
                        search += '&page=1'
                    }
                } else {
                    querySearch = `?lang=${lang}&search=${readSearch},${value}`
                    search = `?search=${readSearch},${value}`
                    if (readCategories) {
                        querySearch += `&categories=${readCategories}`
                        search += `&categories=${readCategories}&page=1`
                    } else {
                        search += '&page=1'
                    }
                }
            } else {

                if (reload) {
                    setTimeout(() => {
                        setIsSearch(false)
                    }, 300)
                    querySearch = `?lang=${lang}`
                    search = ``
                    if (readCategories) {
                        querySearch += `&categories=${readCategories}`
                        search += `?categories=${readCategories}&page=1`
                    } else {
                        search += '?page=1'
                    }
                } else {
                    setTimeout(() => {
                        setIsSearch(true)
                    }, 300)
                    querySearch = `?lang=${lang}&search=${value}`
                    search = `?search=${value}`
                    if (readCategories) {
                        querySearch += `&categories=${readCategories}`
                        search += `&categories=${readCategories}&page=1`
                    } else {
                        search += '&page=1'
                    }

                }
            }

            window.history.pushState({}, null, queryString + search);

            setTimeout(() => {
                getArticle2(querySearch).then(json => {

                    const dates = document.querySelectorAll('[id^="searchElement"]');
                    setCountBefore(dates.length)

                    if (json.items.length == 0) {
                        for (let i = 0; i < dataItems.length; i++) {
                            const obj = document.getElementById(`searchElement-${i}`)
                            obj.style.height = obj.scrollHeight + 'px'
                            setTimeout(() => {
                                obj.style.height = 0;
                            }, 100)
                        }
                    }

                    if (dates.length > json.items.length) {
                        for (let i = json.items.length; i < dates.length; i++) {
                            const obj2 = document.getElementById(`searchElement-${i}`)
                            if (obj2) {
                                obj2.style.height = obj2.scrollHeight + 'px'
                                setTimeout(() => {
                                    obj2.style.height = 0;
                                }, 100)
                            }
                        }
                    }

                    setTimeout(() => {
                        setData(json)
                        setDataItems(json.items)

                        shadowRef.current.classList.remove(styles.itemsWrapperShadowShow)
                        loaderRef.current.classList.remove(styles.loaderShow)

                        searchInputRef.current.value = ''
                        searchInputRef.current.readOnly = false;
                    }, 400)

                })
            }, 500)
        }
    }

    useEffect(() => {
        const savedContainer = sessionStorage.getItem("searchContainer");
    
        if (savedContainer && inputContainer.current) {
            inputContainer.current.innerHTML = savedContainer;
    
            inputContainer.current.querySelectorAll(`.${styles.searchBtn}`).forEach(btn => {
                btn.addEventListener("click", (e) => {
                    shadowRef.current.classList.add(styles.itemsWrapperShadowShow);
                    loaderRef.current.classList.add(styles.loaderShow);
    
                    const removedTerm = e.target.previousSibling.innerText;
                    e.target.parentElement.remove();
                    sessionStorage.setItem("searchContainer", inputContainer.current.innerHTML);
    
                    const urlParams = new URLSearchParams(window.location.search);
                    let searchTerms = urlParams.get("search") ? urlParams.get("search").split(",") : [];
                    searchTerms = searchTerms.filter(term => term !== removedTerm);
    
                    if (searchTerms.length > 0) {
                        urlParams.set("search", searchTerms.join(","));
                    } else {
                        urlParams.delete("search");
                    }
    
                    if (!urlParams.get("search") && urlParams.get("page") === "1") {
                        window.history.pushState({}, "", `${window.location.pathname}?page=1`);
                    } else {
                        window.history.pushState({}, "", `${window.location.pathname}?${urlParams.toString()}`);
                    }
    
                    setTimeout(() => {
                        shadowRef.current.classList.remove(styles.itemsWrapperShadowShow);
                    
                        searchInputRef.current.value = "";
                        searchInputRef.current.readOnly = false;
                    
                        setIsSearch(false);
                    
                        getArticle2(`?lang=${lang}`).then((json) => {
                            setData(json);
                            setDataItems(json.items);
                            setCountBefore(json.items.length);
                        });
                    }, 500);
                });
            });
        }
    }, []);
    


    return (
        <div className={styles.searchComponentWrapper}>
            <form onSubmit={event => searchInfo(event)} className={styles.left} onFocus={() => setHideCategory(true)} onBlur={() => setHideCategory(false)}>
                <div className={styles.searchIcon} />
                <div className={styles.inputContainer} ref={inputContainer}></div>
                <input className={`${styles.inputText} ${hideCategory ? styles.inputTextExpand : ''}`} placeholder={translation.site.main.search} ref={searchInputRef} />
                <button type={'button'} className={`${styles.searchButton} ${closed ? '' : styles.opened}`} onBlur={() => openSortRef.current.focus()} onClick={(event) => searchInfo(event)}>{translation.site.main.search}</button>
            </form>
            <div className={`${styles.right} ${hideCategory ? styles.rightHide : ''}`}>
                <div className={styles.container}>
                    <button id="openSort" type={'button'} ref={openSortRef} className={`${styles.openSort} ${closed ? styles.closed : ''}`}
                        onClick={() => {
                            setClosed(!closed)
                            setTimeout(() => {
                                document.getElementsByClassName(styles.option)[0].focus()
                            }, 200)
                        }}
                    >{size.width > 800 ? translation.site.main.categories.showList : translation.site.main.categories.showListShort}</button>
                    <div className={styles.sortOptionsContainer}>
                        <SlideUpDown closed={closed} closedStart={true}>
                            <div className={styles.optionsContainer}>
                                <div className={styles.optionsWrapper}>
                                    <button
                                        type={"button"}
                                        className={`${styles.option} ${closed ? styles.optionArrowRotate : ''}`}
                                        onClick={() => categoryButtonClick('all', null, true)}
                                        onBlur={() => setClosed(true)}
                                        onFocus={() => setClosed(false)}
                                    >
                                        {size.width > 800 ? translation.site.main.categories.showList : translation.site.main.categories.showListShort}
                                        <div className={styles.arrowBlack} />
                                        <div className={styles.arrowWhite} />
                                    </button>
                                    {categories ? categories.map((item, index) => {
                                        return (
                                            <button
                                                key={index}
                                                type={"button"}
                                                className={styles.option}
                                                onClick={() => categoryButtonClick(item.id, item.name, true)}
                                                onBlur={() => setClosed(true)}
                                                onFocus={() => setClosed(false)}
                                            >
                                                {item.name}
                                            </button>
                                        )
                                    }) : null}
                                </div>
                            </div>
                        </SlideUpDown>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SearchComponent