import React from "react";
import slug from "slug";
import { Link, useParams } from "react-router-dom";

import styles from "./listItem.module.scss";
import {
    exportDay,
    exportMonth,
    exportMonthShort,
    exportYear,
} from "../Helpers/functions";
import { authorUrl } from "../Helpers/myUrlHelper";
import scrollTo from "../Helpers/scrollTo";
import translation from "../Helpers/translation";

const ListItem = ({
    id,
    item,
    animation = false,
    categoryButtonClick,
    tagClick,
}) => {
    const { lang } = useParams();

    function tagClick2(readName, readId) {
        const el = document.getElementById("openSort");
        if (el) {
            el.innerText = readName;
            const topEl = document.getElementById("mainListTop");
            scrollTo(topEl.clientHeight, 500);

            const queryString = window.location.origin + "/" + lang;
            let search = `?categories=${readId}&page=1`;
            window.history.pushState({}, null, queryString + search);

            categoryButtonClick(readId, readName);
        }
    }

    const saveScrollPosition = () => {
        sessionStorage.setItem("scrollPosition", window.scrollY);
        sessionStorage.setItem("fromArticle", "true");
    };
    
    

    return (
        <div
            id={id}
            className={`${styles.item} ${animation ? styles.animation : ""}`}
        >
            <Link
                to={`/${lang}/artykul/${item.id}-${
                    item.title ? slug(item.title) : ""
                }`}
                className={styles.mainLink}
                onClick={saveScrollPosition}
            ></Link>
            <div className={styles.content}>
                <div className={styles.date}>
                    <p className={styles.year}>{exportYear(item.created_at)}</p>
                    <p className={styles.day}>{exportDay(item.created_at)}</p>
                    <p className={styles.month}>
                        {exportMonth(item.created_at, lang)}
                    </p>
                </div>
                <div
                    className={styles.image}
                    style={{ backgroundImage: `url(${item.post_img})` }}
                />
                <div className={styles.textWrapper}>
                    {tagClick ? (
                        <button
                            className={`${styles.categoryButton} ${styles.otherLink}`}
                            dangerouslySetInnerHTML={{
                                __html: item.category_name,
                            }}
                            onClick={() =>
                                tagClick(item.category_name, item.category_id)
                            }
                        ></button>
                    ) : (
                        <a
                            href={`/${lang}?categories=${item.category_id}&page=1`}
                            className={`${styles.categoryButton} ${styles.otherLink}`}
                            dangerouslySetInnerHTML={{
                                __html: item.category_name,
                            }}
                        ></a>
                    )}
                    {/* <Link to={`/${lang}/artykul/${item.id}-${item.title ? slug(item.title) : ''}`} className={styles.otherLink}> */}
                    <h2
                        className={`${styles.titleItem} ${styles.otherLink}`}
                        dangerouslySetInnerHTML={{ __html: item.title }}
                    />
                    {/* </Link> */}
                    <div
                        className={`${styles.textItem} ${styles.clamp4} ${styles.otherLink}`}
                        dangerouslySetInnerHTML={{ __html: item.lead }}
                    />

                    {/* <Link to={`/${lang}/artykul/${item.id}-${item.title ? slug(item.title) : ''}`} className={`${styles.otherLink} ${styles.textItem} ${styles.clamp4}`} dangerouslySetInnerHTML={{ __html: item.lead }}> */}
                    {/* </Link> */}
                    <div className={styles.authors}>
                        {item.author && (
                            <a
                                href={`/${lang}/autors/${authorUrl(item)}`}
                                className={`${styles.link} ${styles.otherClickableLink}`}
                            >
                                <div
                                    className={styles.author}
                                    style={{
                                        backgroundImage: `url("${item.author.img_icon}")`,
                                    }}
                                />
                            </a>
                        )}
                        {/* {item.author.map((author, authorIndex) => {
                            return (
                                <a href={`/${lang}/autors/${authorUrl(author)}`} className={`${styles.link} ${styles.otherClickableLink}`} key={authorIndex}>
                                    <div className={styles.author} style={{ backgroundImage: `url("${author.img_icon}")` }} />
                                </a>
                            );
                        })} */}
                    </div>
                    <Link
                        to={`/${lang}/artykul/${item.id}-${
                            item.title ? slug(item.title) : ""
                        }`}
                        type={"button"}
                        className={`${styles.otherLink} ${styles.readMore}`}
                        onClick={saveScrollPosition}
                    >
                        {translation.site.btns.readMore}
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default ListItem;
